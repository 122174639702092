import { render, staticRenderFns } from "./AccountCore.vue?vue&type=template&id=77996a36"
import script from "./AccountCore.vue?vue&type=script&lang=js"
export * from "./AccountCore.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports