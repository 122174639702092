<template>
  <div>
    <FormHeader />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white">
        <h2 class="mt-20 mb-10">{{ $t('edit_account.edit_address_title') }}</h2>
        <Form
          :validator="$v.model"
          :error="error"
          :messages="{
            minLength: this.$t('registration.contact_info.length_error'),
          }"
        >
          <FormInput
            v-model="model.streetAddr1"
            class="w-full"
            :label="$t('registration.contact_info.street_address_1')"
            name="streetAddress1"
            :placeholder="
              $t('registration.contact_info.street_address_1_place_holder')
            "
            autocomplete="address-line-1"
          />
          <FormInput
            v-model="model.streetAddr2"
            class="mt-16 ml-auto"
            name="streetAddress2"
            :label="$t('registration.contact_info.street_address_2')"
            :placeholder="
              $t('registration.contact_info.street_address_2_place_holder')
            "
            :required="false"
            autocomplete="address-line2"
          />
          <div class="flex flex-wrap py-4 mt-16 ml-auto">
            <FormInput
              v-model="model.city"
              class="w-full md:flex-1"
              name="city"
              :label="$t('registration.contact_info.city')"
              placeholder="New York"
              autocomplete="address-level2"
            />
            <label for="state" class="sr-only">
              {{ $t('registration.contact_info.state') }}
            </label>
            <FormListPicker
              v-model="model.state"
              :compact="true"
              class="w-full pr-4 md:pl-8 md:pr-10 md:flex-1"
              name="state"
              :placeholder="$t('registration.contact_info.state')"
              :label="$t('registration.contact_info.state')"
              autocomplete="address-level1"
              :data="states"
            />
            <FormInput
              v-model="model.zip"
              mask="#####"
              class="w-full md:flex-1"
              name="zipCode"
              :label="$t('registration.contact_info.zip_code')"
              placeholder="77777"
              autocomplete="postal-code"
            />
          </div>
        </Form>
        <FormFooter
          :completeDisabled="isFetching.update"
          :showExitModal="true"
          :exitModalStrings="{
            exitTitle: 'edit_account.exit_title',
            exitDescription: 'edit_account.exit_description',
            exitConfirm: 'edit_account.exit_confirm',
            exitCancel: 'edit_account.exit_cancel',
          }"
          @onComplete="handleUpdateAddress"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../../common/forms/Form'
import FormInput from '../../common/forms/FormInput'
import FormListPicker from '../../common/forms/FormListPicker'
import FormFooter from '../../common/forms/FormFooter.vue'
import FormHeader from '../../common/forms/FormHeader.vue'
import { STATES } from '@platform-shared/constants'
import { required, minLength } from 'vuelidate/lib/validators'
import editAccountMixin from '@platform-shared/mixins/editAccountMixin'

export default {
  components: { Form, FormInput, FormListPicker, FormFooter, FormHeader },
  mixins: [editAccountMixin],
  data() {
    return {
      states: STATES,
      model: {
        streetAddr1: '',
        streetAddr2: '',
        city: '',
        state: 'AL',
        zip: '',
      },
      error: null,
    }
  },
  validations: {
    model: {
      streetAddr1: {
        required,
      },
      streetAddr2: {},
      city: {
        required,
      },
      state: {
        required,
      },
      zip: {
        required,
        validZipCode: minLength(5),
      },
    },
  },
  created() {
    const {
      streetAddr1,
      streetAddr2,
      city,
      state,
      zip,
    } = this.memberInfo.address
    this.model.streetAddr1 = streetAddr1
    this.model.streetAddr2 = streetAddr2
    this.model.city = city
    this.model.state = state
    this.model.zip = zip
  },
}
</script>
<style></style>
