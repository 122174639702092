<template>
  <div>
    <FormHeader />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white">
        <h2 class="mt-20 mb-10">{{ $t('edit_ethnography.title') }}</h2>
        <p class="mt-10 mb-20 text-sm font-semibold">
          {{ $t('edit_ethnography.sub_title') }}
        </p>
        <Form :validator="$v.model" :error="error">
          <FormListPicker
            v-model="model.raceCd"
            class="mt-20"
            :label="$t('edit_ethnography.race')"
            :data="ethnographicInfo.races"
            placeholder="Select"
            name="race"
            :required="false"
          />
          <FormListPicker
            v-model="model.ethnicityCd"
            class="my-20"
            :label="$t('edit_ethnography.ethnicity')"
            :data="ethnographicInfo.ethnicities"
            placeholder="Select"
            name="ethnicity"
            :required="false"
          />
          <FormListPicker
            v-model="model.languageCd"
            :label="$t('edit_ethnography.language')"
            :data="ethnographicInfo.preferredLanguages"
            placeholder="Select"
            name="language"
            :required="false"
          />
        </Form>
        <FormFooter
          :completeDisabled="isFetching.update"
          :showExitModal="true"
          :exitModalStrings="{
            exitTitle: 'edit_account.exit_title',
            exitDescription: 'edit_account.exit_description',
            exitConfirm: 'edit_account.exit_confirm',
            exitCancel: 'edit_account.exit_cancel',
          }"
          @onComplete="handleUpdateEthnography"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FormListPicker, FormFooter, FormHeader } from '../../common/forms'
import Form from '../../common/forms/Form'
import editAccountMixin from '@platform-shared/mixins/editAccountMixin'

export default {
  components: { Form, FormListPicker, FormFooter, FormHeader },
  mixins: [editAccountMixin],
  data: () => ({
    model: {
      raceCd: '',
      ethnicityCd: '',
      languageCd: '',
    },
    error: null,
  }),
  validations: {
    model: {
      raceCd: {},
      ethnicityCd: {},
      languageCd: {},
    },
  },
  created() {
    const { raceCd, ethnicityCd, languageCd } = this.memberInfo.ethnography
    this.model.raceCd = raceCd
    this.model.ethnicityCd = ethnicityCd
    this.model.languageCd = languageCd
  },
}
</script>
<style></style>
