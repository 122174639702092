<template>
  <div class="pt-10 pb-16 border-b border-grey-100">
    <HMButton class="mt-16 mb-10" rounded="full" color="red" @click="openModal">
      {{ $t('edit_account.delete_account') }}
    </HMButton>
    <Modal
      :active="isModalOpen"
      closeOnBackDrop
      class="w-100"
      @close="closeModal"
    >
      <div class="py-20 px-40 max-w-2xl ">
        <H1>
          {{ $t('edit_account.delete_account_verify') }}
        </H1>
        <p class="mt-20">
          {{ $t('edit_account.delete_account_warning') }}
        </p>
        <FormError v-if="error" />
        <div class="mt-20 flex justify-between">
          <HMButton
            class="flex-1"
            rounded="full"
            color="red"
            :disabled="isUnregistering"
            @click="deleteAccount"
          >
            {{ $t('edit_account.delete_account') }}
          </HMButton>
          <HMButton
            class="ml-8"
            rounded="full"
            outlined
            color="white"
            :disabled="isUnregistering"
            @click="closeModal"
          >
            {{ $t('common.cancel_btn') }}
          </HMButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/common/Modal'
import { FormError } from '@components/common/forms'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { Modal, FormError },
  data() {
    return {
      isModalOpen: false,
      error: false,
    }
  },
  computed: {
    ...mapGetters('member', ['isUnregistering']),
  },
  methods: {
    ...mapActions('member', ['unregisterMember']),
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    deleteAccount() {
      this.error = false
      this.unregisterMember().catch(() => {
        this.error = true
      })
    },
  },
}
</script>
