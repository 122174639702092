<template>
  <div class="w-full max-w-3xl py-20 bg-white mb-100">
    <AccountCore />
    <AuthCodeSettings v-if="isAuthCodeSettingsVisible" />
    <AccountDelete />
    <div v-if="memberInfo.lastLogin" class="flex flex-col items-center mt-30">
      <div class="font-semibold text-lg text-grey-500">
        {{ $t('edit_account.last_login') }}
      </div>
      <div class="mt-10">
        {{ memberInfo.lastLogin }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthCodeSettings from './AuthCodeSettings.vue'
import AccountDelete from './AccountDelete.vue'
import { FEATURES } from '@platform-shared/constants'
import AccountCore from './AccountCore.vue'

export default {
  components: { AuthCodeSettings, AccountDelete, AccountCore },
  computed: {
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('member', ['memberInfo']),
    isAuthCodeSettingsVisible() {
      return !this.hasFeature(FEATURES.NO_MOBILE_APP)
    },
  },
}
</script>
