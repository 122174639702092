import { mapGetters, mapActions } from 'vuex'
import _omit from 'lodash/omit'
import _pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import localizeMixin from '@platform-shared/mixins/localizeMixin'
import { FEATURES } from '@platform-shared/constants'

export default {
  data: () => ({}),
  mixins: [localizeMixin],
  computed: {
    ...mapGetters('member', ['memberInfo', 'isFetching']),
    ...mapGetters('client', ['ethnographicInfo', 'hasFeature']),
    displayPreferredMethod() {
      return (
        this.localize('notification_settings.preferred_method') +
        (this.preferredCommunicationMethod
          ? ` ${this.localize(this.preferredCommunicationMethod.title)}`
          : ' N/A')
      )
    },
    race() {
      const result = this.ethnographicInfo.races.find(
        (el) => el.value === get(this.memberInfo, 'ethnography.raceCd', '')
      )
      return result ? result.display : 'N/A'
    },
    ethnicity() {
      const result = this.ethnographicInfo.ethnicities.find(
        (el) => el.value === get(this.memberInfo, 'ethnography.ethnicityCd', '')
      )
      return result ? result.display : 'N/A'
    },
    language() {
      const result = this.ethnographicInfo.preferredLanguages.find(
        (el) => el.value === get(this.memberInfo, 'ethnography.languageCd', '')
      )
      return result ? result.display : 'N/A'
    },
    usesLegacyPage() {
      return this.hasFeature(FEATURES.LEGACY_ACCOUNT_PAGE)
    },
  },
  methods: {
    ...mapActions('member', [
      'updateAddress',
      'updateContactInfo',
      'updateEthnography',
    ]),
    handleUpdateAddress() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.updateAddress(this.model).then(() => {
        this.$router.back()
      })
    },
    handleUpdateContactInfo() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      const payload = this.usesLegacyPage
        ? _omit(this.model, ['email'])
        : this.model

      this.updateContactInfo(_pickBy(payload)).then(() => {
        this.$router.back()
      })
    },
    handleUpdateEthnography() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.updateEthnography(this.model).then(() => {
        this.$router.back()
      })
    },
  },
}
