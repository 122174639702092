var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormHeader'),_c('div',{staticClass:"container flex-grow flex flex-col items-center p-12"},[_c('div',{staticClass:"w-full max-w-3xl py-20 bg-white"},[_c('h2',{staticClass:"mt-20 mb-10"},[_vm._v(_vm._s(_vm.$t('edit_contact.title')))]),_c('Form',{attrs:{"validator":_vm.$v.model,"error":_vm.error,"messages":{
          minLength: _vm.$t('edit_contact.length_error'),
          maxLength: _vm.$t('edit_contact.length_error'),
        }}},[_c('FormInput',{staticClass:"mt-20",attrs:{"label":_vm.$t('edit_contact.home'),"name":"home","placeholder":_vm.$t('edit_contact.place_holder'),"required":false},model:{value:(_vm.model.home),callback:function ($$v) {_vm.$set(_vm.model, "home", $$v)},expression:"model.home"}}),_c('FormInput',{staticClass:"mt-20",attrs:{"label":_vm.$t('edit_contact.cell'),"name":"cell","placeholder":_vm.$t('edit_contact.place_holder'),"required":false},model:{value:(_vm.model.cell),callback:function ($$v) {_vm.$set(_vm.model, "cell", $$v)},expression:"model.cell"}}),_c('FormInput',{staticClass:"mt-20",attrs:{"label":_vm.$t('edit_contact.work'),"name":"work","placeholder":_vm.$t('edit_contact.place_holder'),"required":false},model:{value:(_vm.model.work),callback:function ($$v) {_vm.$set(_vm.model, "work", $$v)},expression:"model.work"}}),(!_vm.usesLegacyPage)?_c('FormInput',{staticClass:"my-20",attrs:{"label":_vm.$t('edit_contact.email'),"name":"email","autocomplete":"email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}):_vm._e()],1),_c('FormFooter',{attrs:{"completeDisabled":_vm.isFetching.update,"showExitModal":true,"exitModalStrings":{
          exitTitle: 'edit_account.exit_title',
          exitDescription: 'edit_account.exit_description',
          exitConfirm: 'edit_account.exit_confirm',
          exitCancel: 'edit_account.exit_cancel',
        }},on:{"onComplete":_vm.handleUpdateContactInfo}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }