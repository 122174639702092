<template>
  <div>
    <FormHeader />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white">
        <h2 class="mt-20 mb-10">{{ $t('edit_password.title') }}</h2>
        <Form
          :validator="$v.model"
          :error="error"
          :messages="{ matching: this.$t('edit_password.matching_error') }"
        >
          <FormInput
            ref="oldPassword"
            v-model="model.oldPassword"
            :label="$t('edit_password.old')"
            name="oldPassword"
            placeholder="••••••••"
            secure
            type="password"
            class="mb-20"
          />
          <FormInput
            ref="password"
            v-model="model.password"
            :label="$t('edit_password.new')"
            name="password"
            placeholder="••••••••"
            secure
            class="mb-20"
            type="password"
          />
          <PasswordRequirements :reqs="passwordRequirements" />
          <FormInput
            ref="confirmPassword"
            v-model="model.confirm"
            :label="$t('edit_password.confirm')"
            name="confirm"
            placeholder="••••••••"
            secure
            class="mb-20 mt-12"
            type="password"
          />
        </Form>
        <FormFooter
          :completeDisabled="isFetching.update"
          :showExitModal="true"
          :exitModalStrings="{
            exitTitle: 'edit_account.exit_title',
            exitDescription: 'edit_account.exit_description',
            exitConfirm: 'edit_account.exit_confirm',
            exitCancel: 'edit_account.exit_cancel',
          }"
          @onComplete="handleUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _every from 'lodash/every'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import Form from '@components/common/forms/Form'
import FormInput from '@components/common/forms/FormInput'
import FormFooter from '@components/common/forms/FormFooter'
import FormHeader from '@components/common/forms/FormHeader'
import password from '@platform-shared/mixins/password'
import PasswordRequirements from '@components/common/PasswordRequirements'

export default {
  components: {
    Form,
    FormInput,
    FormFooter,
    FormHeader,
    PasswordRequirements,
  },
  mixins: [password],
  data: () => ({
    model: {
      oldPassword: '',
    },
    error: null,
  }),
  validations: {
    model: {
      oldPassword: { required },
      password: { required },
      confirm: {
        required,
        matching() {
          return this.model.password === this.model.confirm
        },
      },
    },
  },
  computed: {
    ...mapGetters('member', ['memberInfo', 'isFetching']),
  },
  methods: {
    ...mapActions('member', ['updatePassword']),
    handleUpdate() {
      this.error = null
      this.$v.$touch()
      if (this.$v.$invalid) return
      else if (!this.meetsPasswordRequirements) {
        this.error = this.$t('edit_password.password_error')
        return
      }
      this.updatePassword({
        oldPassword: this.model.oldPassword,
        newPassword: this.model.password,
      })
        .then(() => this.$router.back())
        .catch(() => (this.error = this.$t('edit_password.password_wrong')))
    },
  },
}
</script>
