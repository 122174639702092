<template>
  <div>
    <h2 class="mt-5 mb-20">{{ $t('edit_account.title') }}</h2>
    <div class="pb-16 border-b border-grey-100">
      <p class="text-sm text-grey-500">{{ $t('edit_account.name') }}</p>
      <p class="text-base">{{ fullName }}</p>
    </div>
    <div v-if="!isSSOLogin" class="pt-10 pb-16 border-b border-grey-100">
      <p class="text-sm text-grey-500">{{ $t('edit_account.username') }}</p>
      <p class="text-base">{{ memberInfo.username }}</p>
    </div>
    <div v-if="usesLegacyPage" class="pt-10 pb-16 border-b border-grey-100">
      <p class="text-sm text-grey-500">{{ $t('edit_account.email') }}</p>
      <p class="text-base">{{ memberInfo.email }}</p>
    </div>
    <router-link
      class="flex pt-10 pb-16 border-b cursor-pointer border-grey-100"
      :to="{ name: 'edit-contact' }"
    >
      <div>
        <p class="text-sm text-grey-500">{{ $t('edit_account.contact') }}</p>
        <p class="text-base">
          {{ $t('edit_account.cell', [cell]) }}
        </p>
        <p class="text-base">
          {{ $t('edit_account.home', [home]) }}
        </p>
        <p class="text-base">
          {{ $t('edit_account.work', [work]) }}
        </p>
        <p v-if="!usesLegacyPage" class="text-base">
          {{ $t('edit_account.editable_email', [memberInfo.email]) }}
        </p>
      </div>
      <div class="ml-auto text-sm underline text-blue-600">
        {{ $t('common.edit') }}
      </div>
    </router-link>
    <router-link
      v-if="!usesLegacyPage"
      class="flex pt-10 pb-16 border-b cursor-pointer border-grey-100"
      :to="{ name: 'edit-address' }"
    >
      <div>
        <p class="text-sm text-grey-500">{{ $t('edit_account.address') }}</p>
        <p class="text-base">{{ memberInfo.addressText }}</p>
      </div>
      <div class="ml-auto text-sm underline text-blue-600">
        {{ $t('common.edit') }}
      </div>
    </router-link>
    <div v-else class="pt-10 pb-16 border-b border-grey-100">
      <p class="text-sm text-grey-500">{{ $t('edit_account.address') }}</p>
      <p class="text-base">{{ memberInfo.addressText }}</p>
    </div>
    <router-link
      v-if="!isSSOLogin"
      class="flex pt-10 pb-16 border-b cursor-pointer border-grey-100"
      :to="{ name: 'edit-password' }"
    >
      <div>
        <p class="text-sm text-grey-500">{{ $t('edit_account.password') }}</p>
        <p class="text-base">*************</p>
      </div>
      <div class="ml-auto text-sm underline text-blue-600">
        {{ $t('common.edit') }}
      </div>
    </router-link>
    <router-link
      v-if="!usesLegacyPage"
      class="flex pt-10 pb-16 border-b cursor-pointer border-grey-100"
      :to="{ name: 'edit-ethnography' }"
    >
      <div>
        <p class="text-sm text-grey-500">
          {{ $t('edit_account.ethnography') }}
        </p>
        <p class="text-base">
          {{ $t('edit_account.race', [race]) }}
        </p>
        <p class="text-base">
          {{ $t('edit_account.ethnicity', [ethnicity]) }}
        </p>
        <p class="text-base">
          {{ $t('edit_account.language', [language]) }}
        </p>
      </div>
      <div class="ml-auto text-sm underline text-blue-600">
        {{ $t('common.edit') }}
      </div>
    </router-link>
    <router-link
      v-if="!usesLegacyPage"
      class="flex pt-10 pb-16 border-b cursor-pointer border-grey-100"
      :to="{ name: 'notification-settings' }"
    >
      <div>
        <p class="text-sm text-grey-500">
          {{ $t('notification_settings.title') }}
        </p>
        <p
          v-for="method in communicationMethodToggles"
          :key="method.code"
          class="text-base"
        >
          {{
            `${$t(method.title)}: ${
              method.isAllowed ? $t('common.enabled') : $t('common.disabled')
            }`
          }}
        </p>
        <p class="text-base">
          {{ displayPreferredMethod }}
        </p>
      </div>
      <div class="ml-auto text-sm underline text-blue-600">
        {{ $t('common.edit') }}
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import { formatPhoneNumber } from '@platform-shared/utils/formatters'
import communicationPreferencesFormMixin from '@platform-shared/mixins/communicationPreferencesForm.js'
import editAccountMixin from '@platform-shared/mixins/editAccountMixin'
import { FEATURES } from '@platform-shared/constants'

export default {
  mixins: [communicationPreferencesFormMixin, editAccountMixin],
  computed: {
    ...mapGetters('member', ['memberInfo', 'fullName', 'isSSOLogin']),
    ...mapGetters('client', ['hasFeature', 'ethnographicInfo']),
    cell() {
      return formatPhoneNumber(get(this.memberInfo, 'phoneNumbers.cell', ''))
    },
    work() {
      return formatPhoneNumber(get(this.memberInfo, 'phoneNumbers.work', ''))
    },
    home() {
      return formatPhoneNumber(get(this.memberInfo, 'phoneNumbers.home', ''))
    },
    usesLegacyPage() {
      return this.hasFeature(FEATURES.LEGACY_ACCOUNT_PAGE)
    },
  },
}
</script>
