<template>
  <input
    maxlength="1"
    class="flex-shrink px-8 py-12 text-lg font-bold leading-none text-center text-black uppercase border rounded w-60 h-60 bg-grey-100 border-grey-300 focus:outline-none focus:shadow-outline"
    :value="value"
    @input="$emit('input', $event.target.value)"
    @keyup="focusNext($event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    focusNext(e) {
      if (['Shift', 'Tab', 'Enter', 'Delete', 'Backspace'].includes(e.key))
        return

      e.target.nextSibling && e.target.nextSibling.focus()
    },
  },
}
</script>
