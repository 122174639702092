<template>
  <div>
    <FormHeader />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white flex flex-col">
        <h1 class="text-left w-full">
          {{ $t('notification_settings.title') }}
        </h1>
        <CommunicationPreferencesForm
          :isError="error"
          :isSuccess="success"
          :displayAltSubmission="true"
          @change="resetMessages"
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CommunicationPreferencesForm from './CommunicationPreferencesForm.vue'
import FormHeader from '@components/common/forms/FormHeader'

export default {
  components: { CommunicationPreferencesForm, FormHeader },
  data() {
    return {
      error: false,
      success: false,
    }
  },
  methods: {
    ...mapActions('member', ['createOrUpdateCommunicationPreferences']),
    resetMessages() {
      this.error = false
      this.success = false
    },
    submit(formData) {
      this.resetMessages()
      this.createOrUpdateCommunicationPreferences(formData)
        .then(() => {
          this.success = true
          setTimeout(() => (this.success = false), 3000)
        })
        .catch(() => {
          this.error = true
        })
    },
  },
}
</script>
