<template>
  <div>
    <FormHeader />
    <div class="container flex-grow flex flex-col items-center p-12">
      <div class="w-full max-w-3xl py-20 bg-white">
        <h2 class="mt-20 mb-10">{{ $t('edit_contact.title') }}</h2>
        <Form
          :validator="$v.model"
          :error="error"
          :messages="{
            minLength: $t('edit_contact.length_error'),
            maxLength: $t('edit_contact.length_error'),
          }"
        >
          <FormInput
            v-model="model.home"
            class="mt-20"
            :label="$t('edit_contact.home')"
            name="home"
            :placeholder="$t('edit_contact.place_holder')"
            :required="false"
          />
          <FormInput
            v-model="model.cell"
            class="mt-20"
            :label="$t('edit_contact.cell')"
            name="cell"
            :placeholder="$t('edit_contact.place_holder')"
            :required="false"
          />
          <FormInput
            v-model="model.work"
            class="mt-20"
            :label="$t('edit_contact.work')"
            name="work"
            :placeholder="$t('edit_contact.place_holder')"
            :required="false"
          />
          <FormInput
            v-if="!usesLegacyPage"
            v-model="model.email"
            class="my-20"
            :label="$t('edit_contact.email')"
            name="email"
            autocomplete="email"
          />
        </Form>
        <FormFooter
          :completeDisabled="isFetching.update"
          :showExitModal="true"
          :exitModalStrings="{
            exitTitle: 'edit_account.exit_title',
            exitDescription: 'edit_account.exit_description',
            exitConfirm: 'edit_account.exit_confirm',
            exitCancel: 'edit_account.exit_cancel',
          }"
          @onComplete="handleUpdateContactInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../../common/forms/Form'
import FormInput from '../../common/forms/FormInput'
import FormHeader from '../../common/forms/FormHeader'
import FormFooter from '../../common/forms/FormFooter'
import {
  numeric,
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators'
import editAccountMixin from '@platform-shared/mixins/editAccountMixin'
import { FEATURES } from '@platform-shared/constants'
import { mapGetters } from 'vuex'

export default {
  components: { Form, FormInput, FormFooter, FormHeader },
  mixins: [editAccountMixin],
  data: () => ({
    model: {
      home: '',
      cell: '',
      work: '',
      email: '',
    },
    error: null,
  }),
  validations() {
    const emailValidator = !this.usesLegacyPage
      ? {
          required,
          email,
        }
      : { email }
    return {
      model: {
        home: { numeric, minLength: minLength(10), maxLength: maxLength(10) },
        cell: { numeric, minLength: minLength(10), maxLength: maxLength(10) },
        work: { numeric, minLength: minLength(10), maxLength: maxLength(10) },
        email: emailValidator,
      },
    }
  },
  computed: {
    ...mapGetters('client', ['hasFeature']),
    usesLegacyPage() {
      return this.hasFeature(FEATURES.LEGACY_ACCOUNT_PAGE)
    },
  },
  created() {
    const { home, cell, work } = this.memberInfo.phoneNumbers
    const email = this.memberInfo.email
    this.model.home = home
    this.model.cell = cell
    this.model.work = work
    this.model.email = email
  },
}
</script>
<style></style>
