<template>
  <div class="pt-10 pb-16 border-b border-grey-100">
    <h6>{{ $t('auth_code.title') }}</h6>
    <p class="mt-10">{{ $t('auth_code.description') }}</p>
    <p class="mt-10">{{ $t('auth_code.security') }}</p>
    <HMButton class="mt-20" rounded="full" @click="openModal">
      {{ $t('auth_code.enter') }}
    </HMButton>
    <Modal :active="isModalOpen" closeOnBackDrop @close="closeModal">
      <div class="py-20 text-center w-435 px-30">
        <h6>{{ $t('auth_code.enter') }}</h6>
        <div v-if="isSuccess">
          <p class="w-4/5 mx-auto my-20 text-green-500">
            {{ $t('auth_code.success') }}
          </p>
        </div>
        <div v-else>
          <p class="w-4/5 mx-auto my-20">{{ $t('auth_code.instructions') }}</p>
          <form @submit.prevent="handleSubmit">
            <div class="flex space-x-4">
              <AuthCodeInput id="char1" ref="char1" v-model="model.char1" />
              <AuthCodeInput id="char2" ref="char2" v-model="model.char2" />
              <AuthCodeInput id="char3" ref="char3" v-model="model.char3" />
              <AuthCodeInput id="char4" ref="char4" v-model="model.char4" />
              <AuthCodeInput id="char5" ref="char5" v-model="model.char5" />
              <AuthCodeInput id="char6" ref="char6" v-model="model.char6" />
            </div>
            <div v-if="error" class="mt-20 text-sm text-red-500">
              {{ $t(error) }}
            </div>
            <HMButton
              rounded="full"
              fontWeight="semibold"
              class="w-full mt-20"
              :busy="isSubmitting"
              type="submit"
            >
              {{ $t('common.done_btn') }}
            </HMButton>
          </form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import AuthCodeInput from '@components/common/forms/AuthCodeInput'
import Modal from '@components/common/Modal'
import { verifyUserCode } from '@platform-shared/services/member'

const emptyCode = () => ({
  char1: '',
  char2: '',
  char3: '',
  char4: '',
  char5: '',
  char6: '',
})

export default {
  components: { Modal, AuthCodeInput },
  data() {
    return {
      isModalOpen: false,
      isSuccess: false,
      error: null,
      isSubmitting: false,
      model: emptyCode(),
    }
  },
  computed: {
    userCode() {
      const { char1, char2, char3, char4, char5, char6 } = this.model
      return `${char1}${char2}${char3}${char4}${char5}${char6}`.toUpperCase()
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isSuccess = false
      this.error = null
      this.isModalOpen = false
      Object.assign(this.model, emptyCode())
    },

    async handleSubmit() {
      this.error = null

      if (this.userCode.length !== 6) {
        this.error = 'auth_code.error.required'
        return
      }

      this.isSubmitting = true

      try {
        await verifyUserCode(this.userCode)
        this.isSuccess = true
      } catch (e) {
        this.error = 'auth_code.error.invalid'
      }

      this.isSubmitting = false
    },
  },
}
</script>
