<template>
  <MemberActivitySubmissionWrapper 
    :handleComplete="handleProfileUpdateComplete" 
    :handleCancel="() => this.$router.back()" 
    completeText="common.confirm"
  >
    <AccountCore />
  </MemberActivitySubmissionWrapper>
</template>
  
  <script>
  import MemberActivitySubmissionWrapper from './MemberActivitySubmissionWrapper.vue'
  import AccountCore from '../../../account/AccountCore.vue'
  import memberActivitySubmissionMixin from '@platform-shared/mixins/health-actions/memberActivitySubmissionMixin.js'
  
  export default {
    components: { MemberActivitySubmissionWrapper, AccountCore },
    mixins: [memberActivitySubmissionMixin],
  }
  </script>
  